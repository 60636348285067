/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/seo";
import NavBar from "../components/navBar";
import Footer from "../components/Footer";
// import CalendarButton from "../components/CalendarButton";
import { OutboundLink } from "../components/InternalLink";

// @ts-ignore
import hero from "../images/the_boys2.jpg";
import PrimaryButton from "../components/PrimaryButton";
import { DARK_MODE } from "../constants";

export default function About(props: PageProps) {
  const mainBg = DARK_MODE ? "bg-indigo-600" : "bg-white"
  const sideBg = DARK_MODE ? "bg-indigo-600" : "bg-gray-50"
  const headerText = DARK_MODE ? "text-white " : "text-indigo-600 "
  const mainText = DARK_MODE ? "text-slate-50	" : "text-gray-500"
  const linkText = DARK_MODE ? "font-medium text-slate-50	" : "text-gray-500"

  return (
    <div className={mainBg} id="root">
      <SEO
        title="About"
        description="About Anthony DiFiglio. I’m fascinated by life’s effects on health beyond exercise, food, and medicine. I’ve come to appreciate a broader scope of health that includes everything from the homes we live in to the thoughts that run through our minds each day."
        image={hero}
      />
      <NavBar {...props} />
      <main>
        <div className={mainBg + " overflow-hidden"}>
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div className={sideBg + " hidden lg:block absolute top-0 bottom-0 left-3/4 w-screen"} />
            <div className="mx-auto text-base z-10 max-w-prose lg:grid lg:grid-cols-1 lg:gap-8 lg:max-w-none">
              <h3 className={headerText + "mt-2 text-3xl leading-8 contents lg:hidden font-extrabold tracking-tight sm:text-4xl"}>
                About
              </h3>
            </div>
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative lg:row-start-1 lg:col-start-2">
                <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                  <figure>
                    <div className="aspect-w-12 aspect-h-12 lg:aspect-none">
                      <img
                        className="rounded-lg shadow-lg object-cover object-top"
                        src={hero}
                        alt="Picture of Anthony"
                      />
                    </div>
                  </figure>
                </div>
              </div>
              <div className="mt-8 lg:mt-0">
                <h3 className={headerText + "mt-8 text-3xl hidden lg:contents font-extrabold tracking-tight sm:text-4xl"}>
                  About
                </h3>
                <div className={mainText + " mt-5 prose text-lg lg:text-xl prose-indigo mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1"}>
                  <p>
                    I’m fascinated by the profound effects of health on life and life on health.
                  </p>
                  <p>
                    I can’t possibly express to you the importance of doing things that coincide with our biology and the consequence of not, but I can tell you that I’ve personally overcome and helped others overcome a host of emotional and physical ailments. While my graduate studies have taught me much about the sciences and applications of healing, my life and the lives of those I work with are a testament to the changes that are possible.
                  </p>
                  <p>
                    I’ve studied health ranging from human physiology to home architecture and the universal components of wellness, longevity, and a thriving physical and mental start here.
                  </p>
                  <p>
                    Outside of supporting people on their health journey,
                    I teach breath, meditation, and strength
                    training at the yoga school,{" "}
                    <OutboundLink
                      link="AUTHENTIC_MOVEMENTS"
                      href="https://teach.authenticmovements.com/"
                      className={linkText}
                    >
                      Authentic Movements
                    </OutboundLink>
                    . I'm also the creator and owner of{" "}
                    <OutboundLink
                      link="FEELIO_BLOCKS"
                      href="https://feelioblocks.com/?utm_source=anthony"
                      className={linkText}
                    >
                      Feelio Blocks
                    </OutboundLink>
                    , the one-of-a-kind handcrafted wooden yoga blocks.
                  </p>
                </div>
                <div className="mt-8 sm:mt-12 sm:flex sm:justify-center lg:justify-start">
                  <PrimaryButton analyticsLink="MAIN_FREE_CHAT" text="Free 15 min Chat" href="/chat" />
                  {/* <CalendarButton /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
